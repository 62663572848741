@import './../../../../scss/theme-bootstrap';

.basic-tout {
  position: relative;
  text-align: center;
  // on mobile we only ever have one layout where the image is stacked above the copy. Therefore we need to ensure the copy is legible on white background.
  @include breakpoint($medium-down) {
    // font style is also always the same on mobile for this template
    .font--avenir-light,
    .font--avenir-book,
    .font--avenir-roman,
    .font--avenir-medium,
    .font--avenir-heavy,
    .font--avenir-black {
      font-family: $font--avenir-heavy;
    }
    // !important needed to override potential custom styles that are outputted as inline.
    .font-color--white,
    .link,
    .button {
      color: $color-black !important;
      border-color: $color-black !important;
    }
    &.basic-tout--extend {
      .font-color--white {
        color: $color-white !important;
      }
    }
    .button {
      color: $color-white !important;
      border-color: $color-black !important;
      background: $color-black !important;
    }
  }
}

.basic-tout__image {
  margin: 0 0 10px 0;
  img {
    @include fluid-image;
  }
}

.basic-tout__content {
  padding: 0 10px;
}

.basic-tout__subhead,
.basic-tout__headline,
.basic-tout__description {
  > p {
    margin: 0;
    line-height: inherit;
  }
}

.basic-tout__subhead {
  @include t1;
  font-family: $font--avenir-medium;
  margin: 0 0 3px 0;
}

.basic-tout__headline {
  @include header--medium;
  font-family: $font--avenir-heavy;
  margin: 0 0 7px 0;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.basic-tout__description {
  margin: 0 0 15px 0;
}

.basic-tout__logo {
  margin: 0 0 5px 0;
}

.basic-tout__cta {
  margin: 0 0 10px 0;
  .button {
    @include breakpoint($medium-down) {
      width: 100%;
    }
  }
}

.basic-tout__extole {
  margin-bottom: 10px;
  width: 100%;
  span a {
    text-decoration: none;
    color: $color-white;
  }
  @include breakpoint($medium-up) {
    width: auto;
  }
}

.basic-tout__button {
  @include extended-link;
  &.button {
    border-width: 1px;
    color: $color-white;
    &.button--inverted {
      color: $color-black;
    }
    &.button--large {
      font-size: 19px;
    }
  }
  &--extole {
    margin-bottom: 10px;
  }
}

// Overlay layout style
// applies only on desktop
@include breakpoint($medium-up) {
  .basic-tout--overlay {
    .basic-tout__image {
      margin-bottom: 0;
    }
    .basic-tout__content {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 10%;
      width: 100%;
      padding: 0 7%;
      z-index: 1;
      &--top {
        bottom: auto;
        top: 10%;
      }
      &--center {
        @include vertical-center;
        position: absolute;
        bottom: auto;
      }
    }
  }
}

.basic-tout--extend {
  &.basic-tout--overlay {
    .basic-tout__image {
      margin-bottom: 0;
    }
    .basic-tout__content {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 10%;
      width: 100%;
      padding: 0 7%;
      z-index: 1;
      &--top {
        bottom: auto;
        top: 10%;
      }
      &--center {
        @include vertical-center;
        position: absolute;
        bottom: auto;
      }
    }
  }
}

.email-overlay {
  background: $color-transparent--grey;
  display: none;
  height: 100%;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9001;
  &.active {
    display: block;
    max-height: none;
  }
  .sticky__menu__subnav {
    display: block;
    &__content {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.margin-tweak {
  margin-top: -15px;
}

.tout-go-big {
  .basic-tout__content {
    bottom: 1%;
  }
}

.gift-card {
  .collapsible-block {
    max-width: 800px;
    margin: 0 auto;
  }
}
